export const USER_BASE_API_PATH = 'users/';
export const COUNTRY_LIST_API_PATH = 'countries/';

export const USER_DETAIL_API_PATH = `${USER_BASE_API_PATH}{}/`;
export const USER_BOOKING_WITH_BARBR = `${USER_BASE_API_PATH}{}/user_bookings_barber_specific/`;
export const LOGIN_API_PATH = `${USER_BASE_API_PATH}login/`;
export const REGISTER_API_PATH = `${USER_BASE_API_PATH}register/`;
export const PASSWORD_RESET_OTP_API_PATH = `${USER_BASE_API_PATH}password_reset/send_otp/`;
export const PASSWORD_UPDATE_API_PATH = `${USER_BASE_API_PATH}password_reset/change_password/`;
export const SEND_VERIFICATION_OTP_API_PATH = `${USER_BASE_API_PATH}phone_verification/{}/send_otp/`;
export const VERIFY_VERIFICATION_OTP_API_PATH = `${USER_BASE_API_PATH}phone_verification/{}/verify_otp/`;
export const PHONE_VERIFICATION_LOOKUP_API_PATH = `${USER_BASE_API_PATH}phone_verification/{}/phone_lookup/`;

export const USER_WALLET_API_PATH = `${USER_DETAIL_API_PATH}wallet/`;

export const USER_BOOKING_REVIEW_DATA_API_PATH = 'bookings/data/';
export const USER_BOOKING_API_PATH = 'bookings/user/{}/bookings/';
export const USER_BOOKING_ADDRESSES_API_PATH = 'bookings/user/{}/addresses/';
export const USER_BOOKING_DETAIL_API_PATH = `${USER_BOOKING_API_PATH}{booking_id}/`;

export const BARBER_API_PATH = 'barber/';
export const BARBER_DETAIL_API_PATH = `${BARBER_API_PATH}{}/`;
export const BARBER_PAYMENT_INTENT_API_PATH = `payment/barber/{}/intent/`;
export const BARBER_PAYMENT_METHODS_API_PATH = `payment/barber/{}/payment_methods/`;

export const BOOKING_REVIEW_API_PATH = 'bookings/{}/review/';
export const CUSTOMER_REVIEW_API_PATH = 'bookings/v2/barber_review/review/';

export const BOOKING_UPDATE_TIME = 'bookings/v2/update/time/{booking_id}/';
export const SHOP_DETAIL_API_PATH = '/shop/{}/';

export const SHOP_VISITED_API_PATH = `${SHOP_DETAIL_API_PATH}visited/`;
export const BARBER_VISITED_API_PATH = `${BARBER_DETAIL_API_PATH}visited/`;

// Fix the URL
export const BARBER_REVIEWS_API_PATH = `bookings/barber/{}/reviews/`;
export const BARBER_SHOP_REVIEWS_API_PATH = `${SHOP_DETAIL_API_PATH}reviews/`;
export const BARBER_CLIENT_DETAIL_API_PATH = `bookings/barber/{}/client_detail/`;
export const BARBER_BOOKING_API_PATH = `bookings/barber/{}/booking/{location_type}/`;
export const BARBER_BOOKING_SLOTS_API_PATH = `bookings/barber/{}/available_slots/{type}/`;
export const BARBER_BOOKING_DELETE_API_PATH = `bookings/barber/{}/booking/{booking_id}/delete/`;
export const BARBER_CALLOUT_DISTANCE_CALCULATE_API_PATH = `bookings/barber/{}/calculate_distance/`;
export const BARBER_CALLOUT_DELIVERY_FEE_CALCULATE_API_PATH = `bookings/barber/{}/calculate_delivery_fee/`;
export const BARBER_BOOKING_VALIDATE_DISCOUNT_CODE_API_PATH = `bookings/barber/{}/validate_discount_code/`;

export const USER_BOOKING_DELETE_API_PATH = `bookings/barber/{}/booking/{booking_id}/delete/`;
export const USER_BOOKING_CANCEL_API_PATH = `bookings/barber/{}/booking/{booking_id}/customer/cancel/`;
export const CANCEL_RECURRING_BOOKING = `bookings/v2/cancel/recurring/cancel_booking/`;

export const GOOGLE_PLACE_DETAIL_API_PATH = `/google_place/place_detail?place_id={}`;

export const CHARGE_FEE_CUSTOMER = 'payment/customer/{customerId}/barber/{barberId}/charge-customer/';

export const SAVE_CUSTOMER_CARD = 'payment/barber/{customerId}/save-card/';

export const CUSTOM_NOTIFICATION = '/custom-notification/send/';

// Waiting List API
export const CREATE_WAITING_LIST = '/bookings/v2/waiting_list/';
export const GET_WAITING_LIST = '/bookings/v2/waiting_list?expand=barber,customer';
export const DELETE_WAITING_LIST = '/bookings/v2/waiting_list/{waitingListId}/';
