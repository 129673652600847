import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { setMetaDescription } from 'utils';

const ScrollToTop = ({ history }) => {
  const setMeta = () => {
    document.title = `Barbr - Empowering barbers to thrive`;
    setMetaDescription(`Barbr is a platform that helps barbers save time, earn more and unlock their true potential.`);
  };
  useEffect(() => {
    const unListen = history.listen(() => {
      setMeta();
      document.querySelector('body').scrollTo(0, 0);
    });
    return () => {
      unListen();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToTop);
