import { lazy } from 'react';
import RenderRoutes from './components/renderRoutes/index';
import {
  LOGIN_ROUTE,
  BARBER_BOOKING_SLOT_ROUTE,
  BARBER_PROFILE_ROUTE,
  BARBER_PROFILE_REVIEW_ROUTE,
  INDEX_ROUTE,
  SIGNUP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  SIGNUP_VERIFY_PHONE_ROUTE,
  ACCOUNT_UPDATE_PASSWORD_ROUTE,
  BARBER_BOOKING_CALLOUT_ADDRESS_ROUTE,
  BARBER_BOOKING_CONFIRMATION_ROUTE,
  BARBER_WAITING_LIST_START_ROUTE,
  BARBER_WAITING_LIST_END_ROUTE,
  BARBER_WAITING_LIST_CONFIRMATION_ROUTE,
  BARBER_WAITING_LIST_SUCCESS_ROUTE,
  BARBER_BOOKING_SUCCESS_ROUTE,
  BARBER_REFERRER_ROUTE,
  MY_BOOKINGS_ROUTE,
  PAYMENT_METHODS_ROUTE,
} from './appRoutes/routeConstants';
export const IndexComponent = lazy(() => import('pages'));

export const LoginComponent = lazy(() => import('pages/login'));
export const SignupComponent = lazy(() => import('pages/signup'));
export const SignupVerifyPhoneComponent = lazy(() => import('pages/signup/verifyPhone'));
export const ForgotPasswordComponent = lazy(() => import('pages/login/forgotPassword'));

export const AccountUpdatePasswordComponent = lazy(() => import('pages/account/updatePassword'));

const BarberProfileComponent = lazy(() => import('pages/barber/profile'));
const BookingSuccessComponent = lazy(() => import('pages/booking/success'));
const BookingCallOutComponent = lazy(() => import('pages/booking/callout'));
const BookingSlotComponent = lazy(() => import('pages/booking/timeSelection'));
const BookingConfirmationComponent = lazy(() => import('pages/booking/confirmation'));
const WaitingListTimeComponent = lazy(() => import('pages/waitingList/time'));
const WaitingListComponentConfirmation = lazy(() => import('pages/waitingList/confirmation'));
const WaitingListSuccess = lazy(() => import('pages/waitingList/success'));

const ReferFriendComponent = lazy(() => import('components/refer'));
const MyBookingsComponent = lazy(() => import('pages/customer/myBookings'));
const PaymentMethodComponent = lazy(() => import('pages/customer/paymentMethods'));

export const COMMON_ROUTES = [
  {
    exact: true,
    name: 'login',
    path: LOGIN_ROUTE,
    component: LoginComponent,
  },
  {
    exact: true,
    name: 'booking-slot',
    path: BARBER_BOOKING_SLOT_ROUTE,
    component: BookingSlotComponent,
  },
  {
    exact: true,
    name: 'barber-profile',
    path: BARBER_PROFILE_ROUTE,
    component: BarberProfileComponent,
  },
  {
    exact: true,
    name: 'barber-profile-review',
    path: BARBER_PROFILE_REVIEW_ROUTE,
    component: BarberProfileComponent,
  },
];

export const NON_LOGGED_IN_ROUTES = [
  {
    path: '/',
    name: 'index',
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: 'index',
        path: INDEX_ROUTE,
        component: IndexComponent,
      },
      {
        exact: true,
        name: 'signup',
        path: SIGNUP_ROUTE,
        component: SignupComponent,
      },
      {
        exact: true,
        name: 'forgot-password',
        path: FORGOT_PASSWORD_ROUTE,
        component: ForgotPasswordComponent,
      },
      {
        exact: true,
        name: 'singup-verify-phone',
        path: SIGNUP_VERIFY_PHONE_ROUTE,
        component: SignupVerifyPhoneComponent,
      },
      ...COMMON_ROUTES,
    ],
  },
];

export const LOGGED_IN_ROUTES = [
  {
    path: '/',
    name: 'routes',
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: 'index',
        path: INDEX_ROUTE,
        component: IndexComponent,
      },
      {
        exact: true,
        name: 'singup-verify-phone',
        path: SIGNUP_VERIFY_PHONE_ROUTE,
        component: SignupVerifyPhoneComponent,
      },
      {
        exact: true,
        name: 'account-update-password',
        path: ACCOUNT_UPDATE_PASSWORD_ROUTE,
        component: AccountUpdatePasswordComponent,
      },
      {
        exact: true,
        name: 'booking-callout',
        path: BARBER_BOOKING_CALLOUT_ADDRESS_ROUTE,
        component: BookingCallOutComponent,
      },
      {
        exact: true,
        name: 'booking-confirmation',
        path: BARBER_BOOKING_CONFIRMATION_ROUTE,
        component: BookingConfirmationComponent,
      },
      {
        exact: true,
        name: 'refer-friend',
        path: BARBER_REFERRER_ROUTE,
        component: ReferFriendComponent,
      },
      {
        exact: true,
        name: 'my-bookings',
        path: MY_BOOKINGS_ROUTE,
        component: MyBookingsComponent,
      },
      {
        exact: true,
        name: 'payment-methods',
        path: PAYMENT_METHODS_ROUTE,
        component: PaymentMethodComponent,
      },
      {
        exact: true,
        name: 'waiting-list-start-time',
        path: BARBER_WAITING_LIST_START_ROUTE,
        component: WaitingListTimeComponent,
      },
      {
        exact: true,
        name: 'waiting-list-end-time',
        path: BARBER_WAITING_LIST_END_ROUTE,
        component: WaitingListTimeComponent,
      },
      {
        exact: true,
        name: 'waiting-list-confirmation',
        path: BARBER_WAITING_LIST_CONFIRMATION_ROUTE,
        component: WaitingListComponentConfirmation,
      },
      {
        exact: true,
        name: 'waiting-list-success',
        path: BARBER_WAITING_LIST_SUCCESS_ROUTE,
        component: WaitingListSuccess,
      },
      {
        exact: true,
        name: 'booking-success',
        path: BARBER_BOOKING_SUCCESS_ROUTE,
        component: BookingSuccessComponent,
      },
      ...COMMON_ROUTES,
    ],
  },
];
