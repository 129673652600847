import { useHistory } from 'react-router';
import Button from 'react-bootstrap/Button';
import { INDEX_ROUTE } from 'appRoutes/routeConstants';

const NotFoundPage = () => {
  const history = useHistory();

  const goHome = () => history.push(INDEX_ROUTE);

  return (
    <div className='not-found-page'>
      <div className='text-center'>
        <p className='text-5xl'>Page not found</p>
        <Button size='lg' variant='primary' className='mt-12' onClick={goHome}>
          Go back
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
