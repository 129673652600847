export const INDEX_ROUTE = '/';
export const LOGIN_ROUTE = '/login/';
export const SIGNUP_ROUTE = '/signup/';
export const FORGOT_PASSWORD_ROUTE = `${LOGIN_ROUTE}forgot/`;
export const SIGNUP_VERIFY_PHONE_ROUTE = `${SIGNUP_ROUTE}verify-phone/`;

export const ACCOUNT_UPDATE_PASSWORD_ROUTE = '/account/update-password';

export const BARBER_PROFILE_ROUTE = '/:username/';
export const BARBER_PROFILE_REVIEW_ROUTE = `${BARBER_PROFILE_ROUTE}:k?/`;
export const BARBER_BOOKING_SLOT_ROUTE = `${BARBER_PROFILE_ROUTE}time/`;
export const BARBER_WAITING_LIST_START_ROUTE = `${BARBER_PROFILE_ROUTE}waiting-list/start`;
export const BARBER_WAITING_LIST_END_ROUTE = `${BARBER_PROFILE_ROUTE}waiting-list/end`;

export const BARBER_WAITING_LIST_CONFIRMATION_ROUTE = `${BARBER_PROFILE_ROUTE}waiting-list/confirmation`;
export const BARBER_WAITING_LIST_SUCCESS_ROUTE = `${BARBER_PROFILE_ROUTE}waiting-list/success`;
export const BARBER_REFERRER_ROUTE = `${BARBER_PROFILE_ROUTE}refer-friend/`;
export const BARBER_BOOKING_PROMOTION_ROUTE = `${BARBER_PROFILE_ROUTE}promotions/`;
export const BARBER_BOOKING_CALLOUT_ADDRESS_ROUTE = `${BARBER_PROFILE_ROUTE}callout/`;
export const BARBER_BOOKING_CONFIRMATION_ROUTE = `${BARBER_PROFILE_ROUTE}confirmation/`;
export const BARBER_BOOKING_SUCCESS_ROUTE = `${BARBER_PROFILE_ROUTE}:bookingId/success/`;

export const PROMOTION_ROUTE = '/promotions/';
export const MY_BOOKINGS_ROUTE = '/my-bookings/';
export const PAYMENT_METHODS_ROUTE = '/payment-methods/';
